import React from "react";
import api from "../../api";
import EmptyState from "../../components/EmptyState.jsx";
import ErrorScreen from "../../components/ErrorScreen";
import ResourceRowList from "../../components/ResourceRowList.jsx";
import { DropdownItem } from "../../components/uikit/Dropdown.jsx";
import Filter from "../../components/uikit/Filter.jsx";
import ScreenLoader from "../../components/uikit/ScreenLoader.tsx";
import Stack from "../../components/uikit/Stack";
import { useAsyncCollectionFetch } from "../../state/useAsyncFetch.jsx";
import useClientsideSearchParams from "../../state/useClientsideSearchParams.jsx";
import CommitmentItem from "./CommitmentItem.jsx";
import "./contract-manager.css";

import { useFlags } from "launchdarkly-react-client-sdk";
import LayoutV2 from "../../components/LayoutV2/LayoutV2";
import {
  orgPath,
  useCurrentOrgExternalCodeWithFallback,
} from "../../state/useOrg";
import CommitmentListPageV2 from "./CommitmentListPageV2/CommitmentListPageV2";
function CommitmentListPageV1() {
  const orgExternalCode = useCurrentOrgExternalCodeWithFallback();
  const { loading, error, fetched, items } = useAsyncCollectionFetch(
    api.getOrganizationContractCommitments,
    {
      fetchArgs: { orgExternalCode },
    },
  );
  const { searchParams } = useClientsideSearchParams();
  const period = searchParams.get("period");
  const tracking = searchParams.get("tracking");

  const showCommittment = React.useCallback(
    (commitment) => {
      const trackingOk =
        !tracking ||
        tracking === "all" ||
        commitment.currentCheckin?.progress.tracking === tracking ||
        (tracking === "off_target" &&
          commitment.currentCheckin?.progress.tracking !== "on_target");
      if (!trackingOk) {
        return false;
      }
      const periodOk =
        !period ||
        period === "all" ||
        commitment.contract.activity.name === period;
      if (!periodOk) {
        return false;
      }
      return true;
    },
    [period, tracking],
  );

  const filteredItems = (items || []).filter(showCommittment);
  return (
    <LayoutV2 bg="white">
      {loading && <ScreenLoader />}
      {error && <ErrorScreen />}
      {fetched && (
        <>
          <Stack gap={3} className="page-content">
            <Stack row className="justify-between">
              <h2 className="mb-3">Commitments</h2>
              <Stack row gap={3}>
                <Filter
                  label="Status"
                  defaultValue="all"
                  width={110}
                  queryParamKey="period"
                >
                  <DropdownItem value="all">All</DropdownItem>
                  <DropdownItem value="current">Active</DropdownItem>
                  <DropdownItem value="expired">Expired</DropdownItem>
                  <DropdownItem value="future">Future</DropdownItem>
                </Filter>
                <Filter
                  label="Tracking"
                  defaultValue="all"
                  width={140}
                  queryParamKey="tracking"
                >
                  <DropdownItem value="all">All</DropdownItem>
                  <DropdownItem value="under_target">At Risk</DropdownItem>
                  <DropdownItem value="over_target">Over Target</DropdownItem>
                  <DropdownItem value="off_target">Off Target</DropdownItem>
                  <DropdownItem value="on_target">On Target</DropdownItem>
                </Filter>
              </Stack>
            </Stack>
            <ResourceRowList>
              {filteredItems.map((com) => (
                <CommitmentItem key={com.detailUrl} {...com}></CommitmentItem>
              ))}
            </ResourceRowList>
            {items.length > 0 && filteredItems.length === 0 && (
              <EmptyState className="mt-5">
                No commitments match your filters.
              </EmptyState>
            )}
            {items.length === 0 && (
              <EmptyState
                className="mt-5"
                cta="Upload a Contract"
                ctaHref={orgPath("/contracts/upload", orgExternalCode)}
              >
                Upload a contract to start tracking your spend against
                commitments.
              </EmptyState>
            )}
          </Stack>
        </>
      )}
    </LayoutV2>
  );
}

export default function CommitmentListPage() {
  const { commitmentsPageV2 } = useFlags();
  return commitmentsPageV2 ? (
    <CommitmentListPageV2 />
  ) : (
    <CommitmentListPageV1 />
  );
}

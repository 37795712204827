import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import negate from "lodash/negate";
import partition from "lodash/partition";
import React from "react";
import api from "../../api";
import EmptyState from "../../components/EmptyState.jsx";
import ErrorScreen from "../../components/ErrorScreen";
import { faCirclePlusSolid } from "../../components/icons.jsx";
import ResourceRowList from "../../components/ResourceRowList.jsx";
import Button from "../../components/uikit/Button";
import { DropdownItem } from "../../components/uikit/Dropdown.jsx";
import Filter from "../../components/uikit/Filter.jsx";
import Hr from "../../components/uikit/Hr";
import ScreenLoader from "../../components/uikit/ScreenLoader.tsx";
import Stack from "../../components/uikit/Stack";
import { useAsyncCollectionFetch } from "../../state/useAsyncFetch.jsx";
import useClientsideSearchParams from "../../state/useClientsideSearchParams.jsx";
import {
  orgPath,
  useCurrentOrgExternalCodeWithFallback,
} from "../../state/useOrg";
import "./contract-manager.css";
import ContractItem from "./ContractItem.jsx";

import { useFlags } from "launchdarkly-react-client-sdk";
import LayoutV2 from "../../components/LayoutV2/LayoutV2";
import ContractListPageV2 from "./ContractListPageV2/ContractListPageV2";
function ContractListPageV1() {
  const orgExternalCode = useCurrentOrgExternalCodeWithFallback();
  const { loading, error, fetched, items } = useAsyncCollectionFetch(
    api.getContracts,
    { fetchArgs: { orgExternalCode } },
  );
  const { searchParams } = useClientsideSearchParams();
  const status = searchParams.get("status");

  const showContract = React.useCallback(
    (c) => {
      if (!status || status === "all") {
        return true;
      }
      if (status === "importing") {
        return c.isImporting;
      }
      return c.activity.name === status;
    },
    [status],
  );

  const filteredItems = items.filter(showContract);
  return (
    <LayoutV2 bg="white">
      {loading && <ScreenLoader />}
      {error && <ErrorScreen />}
      {fetched && (
        <>
          <Stack gap={4} className="page-content">
            <Stack row className="justify-between">
              <h2 className="mb-3">Contracts</h2>
              <Filter
                label="Status"
                defaultValue="all"
                width={120}
                queryParamKey="status"
              >
                <DropdownItem value="all">All</DropdownItem>
                <DropdownItem value="current">Active</DropdownItem>
                <DropdownItem value="expired">Expired</DropdownItem>
                <DropdownItem value="future">Future</DropdownItem>
                <DropdownItem value="importing">Importing</DropdownItem>
              </Filter>
            </Stack>
            <Hr />
            <ContractGroups filteredItems={filteredItems} allItems={items} />
          </Stack>
        </>
      )}
    </LayoutV2>
  );
}

function ContractGroups({ allItems, filteredItems }) {
  const orgExternalCode = useCurrentOrgExternalCodeWithFallback();
  if (allItems.length === 0) {
    return (
      <EmptyState
        className="mt-5"
        cta="Upload a Contract"
        ctaHref={orgPath("/contracts/upload", orgExternalCode)}
      >
        Upload a contract to start tracking your spend against commitments.
      </EmptyState>
    );
  }
  const [primary, ppas] = partition(filteredItems, (c) => c.isPrimary);
  const hasPrimary = allItems.some((c) => c.isPrimary);
  const hasPpas = allItems.some(negate((c) => c.isPrimary));
  return (
    <>
      <h5>Primary Contract</h5>
      <PrimaryContracts filtered={primary} hasAny={hasPrimary} />
      <Hr />
      <h5>Private Pricing Agreements</h5>
      <PrivatePricingContracts filtered={ppas} hasAny={hasPpas} />
    </>
  );
}

function PrimaryContracts({ filtered, hasAny }) {
  if (hasAny && filtered.length === 0) {
    return noMatchEmptyState;
  }
  if (hasAny) {
    return filtered.map((c) => <ContractItem key={c.id} {...c} asPrimary />);
  }
  return (
    <ContractItem
      name="Customer Agreement"
      detailUrl="https://aws.amazon.com/agreement/"
      period={{}}
      asPrimary
      isFake
    />
  );
}

function PrivatePricingContracts({ filtered, hasAny }) {
  const orgExternalCode = useCurrentOrgExternalCodeWithFallback();
  if (!hasAny) {
    return (
      <EmptyState
        className="mt-5"
        cta="Upload a Contract"
        ctaHref={orgPath("/contracts/upload", orgExternalCode)}
      >
        Upload a contract to start tracking your spend against commitments.
      </EmptyState>
    );
  }
  const content =
    filtered.length === 0 ? (
      noMatchEmptyState
    ) : (
      <ResourceRowList>
        {filtered.map((c) => (
          <ContractItem key={c.id} {...c} />
        ))}
      </ResourceRowList>
    );
  return (
    <>
      {content}
      <div>
        <Button href={orgPath("/contracts/upload", orgExternalCode)}>
          Add a Contract{" "}
          <FontAwesomeIcon icon={faCirclePlusSolid} className="color-blue" />
        </Button>
      </div>
    </>
  );
}

const noMatchEmptyState = (
  <EmptyState className="mt-5">No contracts match your filters.</EmptyState>
);

export default function ContractListPage() {
  const { contractListPageV2 } = useFlags();
  return contractListPageV2 ? <ContractListPageV2 /> : <ContractListPageV1 />;
}

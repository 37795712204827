import { Container } from "@mantine/core";
import React from "react";
import LayoutV2 from "../../../components/LayoutV2/LayoutV2";

export type ContractScorePageV2PresentationProps = { data: unknown };
export function ContractScorePagePresentation({
  data: _data,
}: ContractScorePageV2PresentationProps): React.JSX.Element {
  return (
    <LayoutV2>
      <Container p="xl">This page is not yet complete.</Container>
    </LayoutV2>
  );
}

export default function ContractScorePage(): React.JSX.Element {
  const data = {};
  return <ContractScorePagePresentation data={data} />;
}

import { Container } from "@mantine/core";
import React from "react";
import LayoutV2 from "../../../components/LayoutV2/LayoutV2";

export type ContractListPageV2PresentationProps = { data: unknown };
export function ContractListPageV2Presentation({
  data: _data,
}: ContractListPageV2PresentationProps): React.JSX.Element {
  return (
    <LayoutV2>
      <Container p="xl">This page is not yet complete.</Container>
    </LayoutV2>
  );
}

export default function ContractListPageV2(): React.JSX.Element {
  const data = {}; // TODO
  return <ContractListPageV2Presentation data={data} />;
}

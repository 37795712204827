import React from "react";
import LayoutV2 from "../../../components/LayoutV2/LayoutV2";
import { Container } from "@mantine/core";

export type DiscountsPagePresentationProps = { data: unknown };
export function DiscountsPagePresentation({
  data: _data,
}: DiscountsPagePresentationProps): React.JSX.Element {
  return (
    <LayoutV2>
      <Container p="xl">This page is not yet complete.</Container>
    </LayoutV2>
  );
}

export default function DiscountsPage(): React.JSX.Element {
  const data = {};
  return <DiscountsPagePresentation data={data} />;
}

import * as Sentry from "@sentry/react";
import { ComponentType } from "react";
import { RouterProvider } from "react-aria-components";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import ErrorScreen from "../components/ErrorScreen.js";
import Redirect from "../components/Redirect.jsx";
import { redirectIfAuthed, redirectIfUnauthed } from "../hocs/authRedirects";
import withMetatags from "../hocs/withMetatags.js";
import applyHocs, { HOC } from "../modules/applyHocs.js";
import renderComponent from "../modules/renderComponent.js";
import AccountCreatedPage from "../pages/AccountCreatedPage.jsx";
import InvitationJumpPage from "../pages/auth/InvitationJumpPage.jsx";
import CommitmentListPage from "../pages/contractmanager/CommitmentListPage.jsx";
import ContractDetailPage from "../pages/contractmanager/ContractDetailPage.jsx";
import ContractListPage from "../pages/contractmanager/ContractListPage.jsx";
import ContractManagerPage from "../pages/contractmanager/ContractManagerPage.jsx";
import ContractScorePage from "../pages/contractmanager/ContractScorePage/ContractScorePage.js";
import ContractUploadPage from "../pages/contractmanager/ContractUploadPage.jsx";
import CreditsListPage from "../pages/contractmanager/CreditsListPage/CreditsListPage.js";
import DiscountsPage from "../pages/contractmanager/DiscountsPage/DiscountsPage.js";
import SpendCommitmentDetailPage from "../pages/contractmanager/SpendCommitmentDetailPage.jsx";
import UsageCommitmentDetailPage from "../pages/contractmanager/UsageCommitmentDetailPage.jsx";
import DashboardPage from "../pages/DashboardPage";
import ForgotPasswordPage from "../pages/ForgotPasswordPage.jsx";
import InvitationsPage from "../pages/InvitationsPage.jsx";
import AwsConnectionPage from "../pages/manageorg/AwsConnectionPage.jsx";
import ManageOrgMembersPage from "../pages/manageorg/ManageOrgMembersPage.jsx";
import DiscountDiscrepancyPage from "../pages/reconciliation/DiscountDiscrepancyPage/DiscountDiscrepancyPage.js";
import MonthlySummaryPage from "../pages/reconciliation/MonthlySummaryPage/MonthlySummaryPage.js";
import RegisterPage from "../pages/RegisterPage";
import ResetPasswordPage from "../pages/ResetPasswordPage.jsx";
import SigninPage from "../pages/SigninPage.jsx";
import SignOutPage from "../pages/SignOutPage.js";
import StyleguidePage from "../pages/StyleguidePage.jsx";
import SupervisedOrganizationsPage from "../pages/SupervisedOrganizationsPage/SupervisedOrganizationsPage.js";
import VerifyEmailPage from "../pages/VerifyEmailPage.jsx";
import ClientsideQueryParamsProvider from "../state/ClientsideQueryParamsProvider";
import useFlexiNavigate from "../state/useFlexiNavigate.js";
import { withScreenLoaderMount } from "../state/useScreenLoader";
import useUser from "../state/useUser";
import config from "./config.js";
import LDContextSetter from "./LDContextSetter.js";

function renderWithHocs(...args: (HOC | ComponentType)[]) {
  const outerComponent = applyHocs(...args);

  return outerComponent ? (
    <>
      {renderComponent(outerComponent)}

      {/* We want to inject this into every route.  It has to be nested
      underneath a `<Route>` since it uses `useParam` from react-router under
      the hood, and that needs to be within a Route. */}
      <LDContextSetter />
    </>
  ) : undefined;
}

export function AppRouter() {
  return (
    <BrowserRouter basename={import.meta.env.BASE_URL}>
      <ClientsideQueryParamsProvider>
        <AppRoutes />
      </ClientsideQueryParamsProvider>
    </BrowserRouter>
  );
}

function AppRoutes() {
  const navigate = useFlexiNavigate();
  return (
    <RouterProvider navigate={navigate}>
      {/* <SetLDContext /> */}
      <AppRoutesInner />
    </RouterProvider>
  );
}

const RedirectWithOrgExternalCode = ({ to }: { to: string }) => {
  const externalCode = useParams().orgExternalCode ?? "";
  return <Redirect to={to.replace(":orgExternalCode", externalCode)} />;
};

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function AppRoutesInner() {
  const { user } = useUser();
  return (
    <SentryRoutes key={user?.id}>
      <Route
        path="/"
        element={renderWithHocs(
          redirectIfAuthed,
          withScreenLoaderMount(),
          withMetatags({ title: "Sign In" }),
          SigninPage,
        )}
      />
      <Route
        path="logout"
        element={renderWithHocs(
          redirectIfUnauthed,
          withScreenLoaderMount(),
          withMetatags({ title: "Sign Out" }),
          SignOutPage,
        )}
      />
      <Route
        path="/register"
        element={renderWithHocs(
          redirectIfAuthed,
          withScreenLoaderMount(),
          withMetatags({ title: "Register" }),
          RegisterPage,
        )}
      />
      <Route
        path="/invitation/:id"
        element={renderWithHocs(
          redirectIfAuthed,
          withMetatags({ title: "Loading" }),
          InvitationJumpPage,
        )}
      />
      <Route
        path="/verify-email"
        element={renderWithHocs(
          redirectIfAuthed,
          withMetatags({ title: "Please wait" }),
          VerifyEmailPage,
        )}
      />
      <Route
        path="/account-created"
        element={renderWithHocs(
          redirectIfAuthed,
          withScreenLoaderMount(),
          withMetatags({ title: "Account Created" }),
          AccountCreatedPage,
        )}
      />
      <Route
        path="/forgot-password"
        element={renderWithHocs(
          redirectIfAuthed,
          withScreenLoaderMount(),
          withMetatags({ title: "Forgot Password" }),
          ForgotPasswordPage,
        )}
      />
      <Route
        path="/reset-password"
        element={renderWithHocs(
          redirectIfAuthed,
          withScreenLoaderMount(),
          withMetatags({ title: "Reset Password" }),
          ResetPasswordPage,
        )}
      />
      <Route
        path="/invitations"
        element={renderWithHocs(
          redirectIfUnauthed,
          withScreenLoaderMount(),
          withMetatags({ title: "Invitations" }),
          InvitationsPage,
        )}
      />
      <Route
        path="/dashboard"
        element={renderWithHocs(
          redirectIfUnauthed,
          withScreenLoaderMount(),
          withMetatags({ title: "Dashboard" }),
          DashboardPage,
        )}
      />
      <Route
        path="/supervised-organizations"
        element={renderWithHocs(
          redirectIfUnauthed,
          withScreenLoaderMount(),
          withMetatags({ title: "Supervised Organizations" }),
          SupervisedOrganizationsPage,
        )}
      />
      <Route
        path="/org/:orgExternalCode/supervised-organizations"
        element={renderWithHocs(
          redirectIfUnauthed,
          withScreenLoaderMount(),
          withMetatags({ title: "Supervised Organizations" }),
          SupervisedOrganizationsPage,
        )}
      />
      <Route
        path="/org/:orgExternalCode/dashboard"
        element={renderWithHocs(
          redirectIfUnauthed,
          withScreenLoaderMount(),
          withMetatags({ title: "Dashboard" }),
          DashboardPage,
        )}
      />
      <Route
        path="/org/:orgExternalCode/manage-org"
        element={
          <RedirectWithOrgExternalCode
            to={"/org/:orgExternalCode/manage-org/members"}
          />
        }
      />
      <Route
        path="/org/:orgExternalCode/manage-org/members"
        element={renderWithHocs(
          redirectIfUnauthed,
          withScreenLoaderMount(),
          withMetatags({ title: "Members" }),
          ManageOrgMembersPage,
        )}
      />
      <Route
        path="/org/:orgExternalCode/manage-org/aws-connection"
        element={renderWithHocs(
          redirectIfUnauthed,
          withScreenLoaderMount(),
          withMetatags({ title: "AWS Connection" }),
          AwsConnectionPage,
        )}
      />
      <Route
        path="/org/:orgExternalCode/contracts"
        element={renderWithHocs(
          redirectIfUnauthed,
          withScreenLoaderMount(),
          withMetatags({ title: "Contract Manager" }),
          ContractManagerPage,
        )}
      />
      <Route
        path="/org/:orgExternalCode/contracts/credits"
        element={renderWithHocs(
          redirectIfUnauthed,
          withScreenLoaderMount(),
          withMetatags({ title: "Contract Manager" }),
          CreditsListPage,
        )}
      />
      <Route
        path="/org/:orgExternalCode/contracts/commitments"
        element={renderWithHocs(
          redirectIfUnauthed,
          withScreenLoaderMount(),
          withMetatags({ title: "Commitments | Contracts" }),
          CommitmentListPage,
        )}
      />
      <Route
        path="/org/:orgExternalCode/contracts/spend-commitments/:id"
        element={renderWithHocs(
          redirectIfUnauthed,
          withScreenLoaderMount(),
          withMetatags({ title: "Spend Commitment | Contracts" }),
          SpendCommitmentDetailPage,
        )}
      />
      <Route
        path="/org/:orgExternalCode/contracts/usage-commitments/:id"
        element={renderWithHocs(
          redirectIfUnauthed,
          withScreenLoaderMount(),
          withMetatags({ title: "Usage Commitment | Contracts" }),
          UsageCommitmentDetailPage,
        )}
      />
      <Route
        path="/org/:orgExternalCode/contracts/list"
        element={renderWithHocs(
          redirectIfUnauthed,
          withScreenLoaderMount(),
          withMetatags({ title: "Contracts" }),
          ContractListPage,
        )}
      />
      <Route
        path="/org/:orgExternalCode/contracts/score"
        element={renderWithHocs(
          redirectIfUnauthed,
          withScreenLoaderMount(),
          withMetatags({ title: "Contracts" }),
          ContractScorePage,
        )}
      />
      <Route
        path="/org/:orgExternalCode/contracts/discounts"
        element={renderWithHocs(
          redirectIfUnauthed,
          withScreenLoaderMount(),
          withMetatags({ title: "Contracts" }),
          DiscountsPage,
        )}
      />
      <Route
        path="/org/:orgExternalCode/contracts/upload"
        element={renderWithHocs(
          redirectIfUnauthed,
          withScreenLoaderMount(),
          withMetatags({ title: "Upload Contract" }),
          ContractUploadPage,
        )}
      />
      <Route
        path="/org/:orgExternalCode/contracts/:id"
        element={renderWithHocs(
          redirectIfUnauthed,
          withScreenLoaderMount(),
          withMetatags({ title: "Contract" }),
          ContractDetailPage,
        )}
      />
      <Route
        path="/org/:orgExternalCode/reconciliation/monthly"
        element={renderWithHocs(
          redirectIfUnauthed,
          withScreenLoaderMount(),
          withMetatags({ title: "Reconciliation" }),
          MonthlySummaryPage,
        )}
      />
      <Route
        path="/org/:orgExternalCode/reconciliation/discount-discrepancies"
        element={renderWithHocs(
          redirectIfUnauthed,
          withScreenLoaderMount(),
          withMetatags({ title: "Reconciliation" }),
          DiscountDiscrepancyPage,
        )}
      />
      <Route
        path="/error"
        element={renderWithHocs(withMetatags({ title: "Error" }), () => (
          <div className="mt-4">
            <ErrorScreen />
          </div>
        ))}
      />
      {config.styleguide && (
        <Route
          path="/styleguide/:section?"
          element={renderWithHocs(
            withMetatags({ title: "Styleguide" }),
            StyleguidePage,
          )}
        />
      )}
      <Route path="/*" element={<Redirect to="/" />} />
    </SentryRoutes>
  );
}

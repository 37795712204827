import React from "react";
import LayoutV2 from "../../../components/LayoutV2/LayoutV2";
import { Container } from "@mantine/core";

export type CommitmentListPageV2PresentationProps = { data: unknown };
export function CommitmentListPageV2Presentation({
  data: _data,
}: CommitmentListPageV2PresentationProps): React.JSX.Element {
  return (
    <LayoutV2>
      <Container p="xl">This page is not yet complete.</Container>
    </LayoutV2>
  );
}

export default function CommitmentListPageV2(): React.JSX.Element {
  const data = {};
  return <CommitmentListPageV2Presentation data={data} />;
}

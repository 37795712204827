import { faCircleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, AppShell, Box, Text } from "@mantine/core";
import { useHeadroom } from "@mantine/hooks";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { ComponentProps } from "react";
import HeaderV2 from "./HeaderV2";
import SideNavV2 from "./SideNavV2";

export type LayoutV2Props = {
  children: React.ReactNode;
  bg?: ComponentProps<typeof AppShell.Main>["bg"];
};

export default function LayoutV2({
  bg,
  children,
}: LayoutV2Props): React.JSX.Element {
  const pinned = useHeadroom({ fixedAt: 120 });
  const { globalBetaBanner } = useFlags();

  return (
    <AppShell
      header={{ height: 60, collapsed: !pinned }}
      navbar={{ width: 300, breakpoint: 1 }}
      c="gray.8"
    >
      <HeaderV2 />
      <SideNavV2 />
      <AppShell.Main bg={bg ?? "gray.0"}>
        <Box maw={1200}>
          {globalBetaBanner && (
            <Alert
              title={
                <Text fz="md" fw="bold" component="span">
                  We&apos;re in beta
                </Text>
              }
              color="red"
              m="lg"
              mb={0}
              icon={<FontAwesomeIcon icon={faCircleExclamation} size="1x" />}
              variant="light"
              lh="lg"
            >
              Not all cost categories and contract mechanisms are implemented.
              Use current data with caution and an external reference.
              We&apos;re happy to answer questions and will announce as features
              transition out of beta.
            </Alert>
          )}
          {children}
        </Box>
      </AppShell.Main>
    </AppShell>
  );
}
